import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';
import Tune from '../api/tune/tune';

function registrationPopup() {
	const $body = document.body;
	const $registrationPopup = document.querySelector('.js-registrationPopup');
	const $openRegistrationPopup = document.querySelector('.js-openRegistrationPopup');
	const $closeRegistrationPopup = document.querySelector('.js-closeRegistrationPopup');
	const $selectCountry = document.querySelector('#userRegCountry');
	const $selectState = document.querySelector('#userRegState');
	const $successPopup = document.querySelector('.js-successPopup');
	const $popupErrorText = $successPopup.querySelector('.js-popupErrorText');

	function displayErrors(errors, targetSelector) {
		const targetElement = targetSelector;

		if (!targetElement || !Array.isArray(errors) || errors.length === 0) {
			console.error('Invalid target element or empty errors array');
			return;
		}

		const errorList = document.createElement('ul');

		errors.forEach((error) => {
			const errorListItem = document.createElement('li');
			errorListItem.textContent = `Error: ${error.err_msg}` || 'Unknown error';
			errorList.appendChild(errorListItem);
		});

		targetElement.innerHTML = '';
		targetElement.appendChild(errorList);
	}

	const selectOptions = {
		removeItemButton: false,
		searchEnabled: true,
		itemSelectText: '',
		allowHTML: true
	};

	const stateClasses = {
		invalidField: '--invalid-field',
		popupVisibleState: 'popup--visible-state',
		popupErrorState: 'popup--error-state',
		bodyPopupOpenedState: '--popup-opened-state',
		buttonDisabledState: 'popup__form-button-item--disabled-state',
		popupFormTabVisibleState: 'popup__form-tab--visible-state',
		visibleErrorState: '--visible-error-state'
	};

	const selectCountryInit = new Choices($selectCountry, selectOptions);
	const selectStateInit = new Choices($selectState, selectOptions);

	const tuneInstance = new Tune();
	tuneInstance.applicationFindAllCountries().then((countries) => {
		if (countries.data) {
			const filteredCountries = { ...countries.data };

			if ('**' in filteredCountries) {
				delete filteredCountries['**'];
			}
			if ('?' in filteredCountries) {
				delete filteredCountries['?'];
			}

			const countryArray = Object.values(filteredCountries).map((country) => country.Country);

			// console.log(countryArray);

			countryArray.forEach((item) => {
				selectCountryInit.setChoices([{ value: item.code.toString(), label: item.name }], 'value', 'label', false);
				// console.log(item);
			});

			$selectCountry.addEventListener('change', async (event) => {
				const selectedCountryCode = event.target.value;
				selectStateInit.removeActiveItems();
				selectStateInit.clearChoices();

				try {
					const response = await tuneInstance.applicationGetCountryRegions(selectedCountryCode);
					if (response.data) {
						const regionsArray = Object.values(response.data)
							.map((item) => item.Region)
							.filter((region) => region.code !== '?');

						// console.log(regionsArray, 'regionsArray');

						const statesOptions = regionsArray.map((region) => ({
							value: region.name,
							label: region.name
						}));

						// console.log(statesOptions, 'statesOptions');

						selectStateInit.setChoices(statesOptions, 'value', 'label', false);
					}
				} catch (error) {
					console.error('Error fetching regions:', error);
				}
			});
		}
	});

	if (typeof $registrationPopup !== 'undefined' && $registrationPopup != null) {
		$openRegistrationPopup.addEventListener('click', () => {
			$registrationPopup.classList.add(stateClasses.popupVisibleState);
			$body.classList.add(stateClasses.bodyPopupOpenedState);
		});

		$closeRegistrationPopup.addEventListener('click', () => {
			$registrationPopup.classList.remove(stateClasses.popupVisibleState);
			$body.classList.remove(stateClasses.bodyPopupOpenedState);
		});
	}

	// Этапы регистрации и кнопки
	const tabs = Array.from(document.querySelectorAll('.popup__form-tab'));
	const nextButton = document.querySelector('.js-nextButton');
	const prevButton = document.querySelector('.js-prevButton');
	const submitButton = document.querySelector('.js-submitButton');
	const nextButtonWrapper = document.querySelector('.js-nextButtonWrapper');
	const prevButtonWrapper = document.querySelector('.js-prevButtonWrapper');
	const submitButtonWrapper = document.querySelector('.js-submitButtonWrapper');

	tabs.forEach((el, index) => {
		const tab = el;
		tab.dataset.stepIndex = index.toString();
	});

	nextButton.addEventListener('click', (event) => {
		event.preventDefault();

		const currentTab = tabs.find((tab) => tab.classList.contains(stateClasses.popupFormTabVisibleState));
		const inputs = Array.from(currentTab.querySelectorAll('input'));
		const checkbox = currentTab.querySelector('input[type="checkbox"]');
		const selects = Array.from(currentTab.querySelectorAll('select'));
		const invalidInputs = [];

		const invalidFields = document.querySelectorAll('.--invalid-field');
		invalidFields.forEach((field) => field && field.classList.remove(stateClasses.invalidField));

		inputs.forEach((input) => {
			if (!input.checkValidity()) {
				invalidInputs.push(input);
			}
		});

		if (checkbox && !checkbox.checked) {
			invalidInputs.push(checkbox);
		}

		selects.forEach((select) => {
			const selectedOption = select.querySelector('option:checked');
			if (!selectedOption || selectedOption.disabled) {
				invalidInputs.push(select);
			}
		});

		invalidInputs.forEach((input) => {
			input.classList.add(stateClasses.invalidField);

			if (input.classList.contains('choices__input')) {
				const choicesInner = input.closest('.choices__inner');
				if (choicesInner) {
					choicesInner.classList.add(stateClasses.invalidField);
				}
			}
		});

		if (invalidInputs.length === 0) {
			const nextTabIndex = Number(currentTab.dataset.stepIndex) + 1;
			const nextTab = document.querySelector(`.popup__form-tab[data-step-index="${nextTabIndex}"]`);
			if (nextTab) {
				prevButtonWrapper.classList.remove(stateClasses.buttonDisabledState);
				currentTab.classList.remove(stateClasses.popupFormTabVisibleState);
				nextTab.classList.add(stateClasses.popupFormTabVisibleState);
				if (!tabs[nextTabIndex + 1]) {
					nextButtonWrapper.classList.add(stateClasses.buttonDisabledState);
					submitButtonWrapper.classList.remove(stateClasses.buttonDisabledState);
				}
			}
		}
	});

	prevButton.addEventListener('click', (event) => {
		event.preventDefault();

		const currentTab = [...tabs].find((tab) => tab.classList.contains(stateClasses.popupFormTabVisibleState));
		if (currentTab) {
			const prevTabIndex = Number(currentTab.dataset.stepIndex) - 1;
			const prevTab = document.querySelector(`.popup__form-tab[data-step-index="${prevTabIndex}"]`);
			if (prevTab) {
				currentTab.classList.remove(stateClasses.popupFormTabVisibleState);
				prevTab.classList.add(stateClasses.popupFormTabVisibleState);
				nextButtonWrapper.classList.remove(stateClasses.buttonDisabledState);
				submitButtonWrapper.classList.add(stateClasses.buttonDisabledState);
				if (!tabs[prevTabIndex - 1]) {
					prevButtonWrapper.classList.add(stateClasses.buttonDisabledState);
				}
			}
		}
	});

	const password = document.querySelector('#userRegPassword');
	const confirmPassword = document.querySelector('#userRegRepeatPassword');
	const reliabilityIndicator = document.querySelector('.js-reliabilityIndicator');
	const errorConfirm = document.querySelector('.js-errorConfirm');

	const indicatorStateClasses = {
		weak: '--indicator-weak-state',
		medium: '--indicator-medium-state',
		strong: '--indicator-strong-state'
	};
	function addIndicatorState(state) {
		if (state === 1 && reliabilityIndicator.classList.contains(indicatorStateClasses.medium)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.medium);
			reliabilityIndicator.classList.add(indicatorStateClasses.weak);
		} else if (state === 1 && reliabilityIndicator.classList.contains(indicatorStateClasses.strong)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.strong);
			reliabilityIndicator.classList.add(indicatorStateClasses.weak);
		} else if (state === 1) {
			reliabilityIndicator.classList.add(indicatorStateClasses.weak);
		} else if (state === 2 && reliabilityIndicator.classList.contains(indicatorStateClasses.weak)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.weak);
			reliabilityIndicator.classList.add(indicatorStateClasses.medium);
		} else if (state === 2 && reliabilityIndicator.classList.contains(indicatorStateClasses.strong)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.strong);
			reliabilityIndicator.classList.add(indicatorStateClasses.medium);
		} else if (state === 2) {
			reliabilityIndicator.classList.add(indicatorStateClasses.medium);
		} else if (state === 3 && reliabilityIndicator.classList.contains(indicatorStateClasses.weak)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.weak);
			reliabilityIndicator.classList.add(indicatorStateClasses.strong);
		} else if (state === 3 && reliabilityIndicator.classList.contains(indicatorStateClasses.medium)) {
			reliabilityIndicator.classList.remove(indicatorStateClasses.medium);
			reliabilityIndicator.classList.add(indicatorStateClasses.strong);
		} else if (state === 3) {
			reliabilityIndicator.classList.add(indicatorStateClasses.strong);
		}
	}

	password.addEventListener('input', () => {
		const passwordVal = password.value;

		if (passwordVal.length === 0) {
			Object.values(indicatorStateClasses).forEach((className) => {
				reliabilityIndicator.classList.remove(className);
			});
			return;
		}

		if (passwordVal.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/)) {
			addIndicatorState(3);
			// console.log('strongState');
		} else if (passwordVal.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{5,7})/)) {
			addIndicatorState(2);
			// console.log('mediumState');
		} else {
			addIndicatorState(1);
			// console.log('weakState');
		}
	});

	function doPasswordsMatch(el, el2) {
		if (el.value !== el2.value) {
			el2.classList.add(stateClasses.invalidField);
			errorConfirm.classList.add(stateClasses.visibleErrorState);
			return false;
		}
		el2.classList.remove(stateClasses.invalidField);
		errorConfirm.classList.remove(stateClasses.visibleErrorState);
		return true;
	}

	confirmPassword.addEventListener('blur', () => {
		doPasswordsMatch(password, confirmPassword);
	});

	function getGroupedFormData(inputs) {
		return inputs.reduce(
			(acc, element) => {
				let key = '';

				if (element.name.startsWith('affiliate')) {
					key = 'affiliate';
				} else if (element.name.startsWith('user')) {
					key = 'user';
				} else {
					return acc;
				}

				// Remove group prefix
				const name = element.name.replace(new RegExp(`^${key}\\[(.+)\\]$`), '$1');

				if (element.type === 'checkbox' || element.type === 'radio') {
					acc[key][name] = element.checked;
				} else {
					acc[key][name] = element.value;
				}

				return acc;
			},
			{ affiliate: {}, user: {} }
		);
	}

	function openLoginPopup() {
		const $loginPopup = document.querySelector('.js-loginPopup');
		$loginPopup.classList.add(stateClasses.popupVisibleState);

		$successPopup.classList.remove(stateClasses.popupVisibleState);
	}

	function successPopup() {
		$registrationPopup.classList.remove(stateClasses.popupVisibleState);
		$successPopup.classList.add(stateClasses.popupVisibleState);

		const $successOpenLoginPopup = document.querySelector('.js-openLoginPopup2');
		$successOpenLoginPopup.addEventListener('click', () => openLoginPopup());
	}

	submitButton.addEventListener('click', (event) => {
		event.preventDefault();
		const inputs = Array.from(document.querySelectorAll('.popup__form-tab input, .popup__form-tab select'));
		const invalidInputs = inputs.filter((input) => !input.checkValidity());

		invalidInputs.forEach((input) => input.classList.add(stateClasses.invalidField));

		if (invalidInputs.length === 0) {
			try {
				submitButton.disabled = true;
				// submit form
				const groupedFormData = getGroupedFormData(inputs);
				// set affiliate status <active, pending, blocked, closed, rejected>
				groupedFormData.affiliate.status = 'active';

				// Check user unique email
				// groupedFormData.user.email = '';

				tuneInstance.affiliateUserUniqueEmail(groupedFormData.user.email).then((emailIsUnique) => {
					// if email is unique then go to create affiliate
					if (emailIsUnique.data === true) {
						const affiliateFormData = groupedFormData.affiliate;
						// create affiliate
						tuneInstance.affiliateCreate(affiliateFormData).then((affiliateResponse) => {
							// If affiliate is created then goto create user
							if (affiliateResponse.data && affiliateResponse.data.Affiliate.id) {
								// relation affiliate.id with user.affiliate_id
								groupedFormData.user.affiliate_id = affiliateResponse.data.Affiliate.id;
								const userFormData = groupedFormData.user;

								// create user
								tuneInstance.affiliateUserCreate(userFormData).then((userResponse) => {
									if (userResponse.data && userResponse.data.AffiliateUser.id) {
										// if User created successfully then show popup
										if ($registrationPopup.classList.contains(stateClasses.popupErrorState)) {
											$registrationPopup.classList.remove(stateClasses.popupErrorState);
											$popupErrorText.innerHTML = '';
										}

										successPopup();
									} else {
										// Need show message if user is not created
										console.log('failed to create User', userResponse.errors);
										displayErrors(userResponse.errors, $popupErrorText);
										$registrationPopup.classList.add(stateClasses.popupErrorState);
									}
								});
							} else {
								// Need show message if Affiliate is not created
								console.log('failed to create Affiliate', affiliateResponse.errors);
								displayErrors(affiliateResponse.errors, $popupErrorText);
								$registrationPopup.classList.add(stateClasses.popupErrorState);
							}
						});
					} else {
						const userRegEmail = document.querySelector('#userRegEmail');
						userRegEmail.classList.add(stateClasses.invalidField);
						console.log('Email not unique', emailIsUnique.errors);
						displayErrors(emailIsUnique.errors, $popupErrorText);
						$registrationPopup.classList.add(stateClasses.popupErrorState);
					}
				});
			} catch (error) {
				console.error('An error occurred:', error);
			} finally {
				submitButton.disabled = false;
			}
		}
	});
}

export default registrationPopup;
