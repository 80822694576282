class Mail {
	constructor() {
		this.RESET_PASSWORD_MAIL = '/mail.php';
		this.FROM = 'gelt@gelt.biz';
	}

	async sendResetEmail(to, subject, text, token) {
		const from = this.FROM;
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ from, to, subject, text, recaptcha_response: token })
		};

		try {
			const response = await fetch(this.RESET_PASSWORD_MAIL, options);

			if (!response.ok) {
				const errorMessage = `Failed to send email. Message: ${response.message}`;
				throw new Error(errorMessage);
			}

			return response.json();
		} catch (error) {
			console.error('Error sending email:', error.message);
			throw new Error('Failed to send email. Please try again later.');
		}
	}
}

export default Mail;
