function prepareDataParam(formData) {
	return Object.keys(formData).reduce((result, key) => {
		const encodedKey = `data[${key}]`;
		const encodedValue = formData[key];
		return { ...result, [encodedKey]: encodedValue };
	}, {});
}

class Tune {
	constructor() {
		this.API_DOMAIN = process.env.API_URL;
		this.API_KEY = process.env.API_TOKEN;
	}

	async fetchData(target, method, httpMethod, params = {}) {
		const baseURL = this.API_DOMAIN;

		const searchParams = new URLSearchParams({
			NetworkToken: this.API_KEY,
			Target: target,
			Method: method
		});

		Object.keys(params).forEach((key) => {
			searchParams.append(key, params[key]);
		});

		const response = await fetch(`${baseURL}?${searchParams.toString()}`, {
			method: httpMethod
		});

		if (!response.ok) {
			throw new Error(`API call failed: ${response.status}`);
		}

		const jsonResponse = await response.json();
		return jsonResponse.response;
	}

	affiliateCreate(formData) {
		return this.fetchData('Affiliate', 'create', 'POST', prepareDataParam(formData));
	}

	affiliateUserCreate(formData) {
		return this.fetchData('AffiliateUser', 'create', 'POST', prepareDataParam(formData));
	}

	affiliateUserUniqueEmail(email) {
		return this.fetchData('AffiliateUser', 'uniqueEmail', 'POST', { email });
	}

	applicationFindAllCountries() {
		return this.fetchData('Application', 'findAllCountries', 'GET');
	}

	applicationGetCountryRegions(code) {
		return this.fetchData('Application', 'getCountryRegions', 'GET', { code });
	}

	authenticationFindUserByCredentials(data) {
		return this.fetchData('Authentication', 'findUserByCredentials', 'GET', data);
	}

	authenticationResetEmail(email) {
		return this.fetchData('Application', 'resetPassword', 'POST', { email });
	}
}

export default Tune;
