import { load } from 'recaptcha-v3';
import Tune from '../api/tune/tune';
import Mail from '../utils/mail';

function loginPopup() {
	const $body = document.body;
	const $loginPopup = document.querySelector('.js-loginPopup');
	const openLoginPopupArray = document.querySelectorAll('.js-openLoginPopup');
	const $closeLoginPopup = document.querySelector('.js-closeLoginPopup');
	const $loginPopupForm = document.querySelector('.js-loginPopup .popup__form');
	const $resetPopup = document.querySelector('.js-resetPopup');
	const $resetPopupForm = document.querySelector('.js-resetPopup .popup__form');
	const $popupErrorText = $resetPopup.querySelector('.js-popupErrorText');
	const $openResetPopup = document.querySelector('.js-openResetPopup');
	const $closeResetPopup = document.querySelector('.js-closeResetPopup');
	const $successResetPopup = document.querySelector('.js-successResetPopup');

	function displayErrors(errors, targetSelector) {
		const targetElement = targetSelector;

		console.log('Errors:', errors, 'Target element:', targetElement);

		if (!targetElement || !Array.isArray(errors) || errors.length === 0) {
			console.error('Invalid target element or empty errors array');
			return;
		}

		const errorList = document.createElement('ul');

		errors.forEach((error) => {
			const errorListItem = document.createElement('li');
			errorListItem.textContent = `Error: ${error.err_msg}` || 'Unknown error';
			errorList.appendChild(errorListItem);
		});

		targetElement.innerHTML = '';
		targetElement.appendChild(errorList);
	}

	const tuneInstance = new Tune();
	const sendMail = new Mail();

	const stateClasses = {
		popupVisibleState: 'popup--visible-state',
		bodyOpenPopupState: '--popup-opened-state',
		invalidField: '--invalid-field',
		popupErrorState: 'popup--error-state'
	};

	function closePopup() {
		const popupElement = document.querySelector(`.${stateClasses.popupVisibleState}`);
		if (popupElement) {
			popupElement.classList.remove(stateClasses.popupVisibleState);
			$body.classList.remove(stateClasses.bodyOpenPopupState);
		}
	}

	function openPopup(elem) {
		closePopup();
		elem.classList.add(stateClasses.popupVisibleState);
		$body.classList.add(stateClasses.bodyOpenPopupState);
	}

	if (typeof $loginPopup !== 'undefined' && $loginPopup != null) {
		openLoginPopupArray.forEach((elem) => {
			elem.addEventListener('click', () => openPopup($loginPopup));
		});

		$closeLoginPopup.addEventListener('click', () => closePopup());

		$openResetPopup.addEventListener('click', () => openPopup($resetPopup));

		$closeResetPopup.addEventListener('click', () => openPopup($resetPopup));
	}

	$loginPopupForm.addEventListener('submit', (event) => {
		event.preventDefault();

		const emailElem = document.querySelector('#userEmail');
		const email = emailElem.value;
		const passwordElem = document.querySelector('#userPassword');
		const password = passwordElem.value;

		emailElem.classList.remove(stateClasses.invalidField);
		passwordElem.classList.remove(stateClasses.invalidField);

		if (!password) {
			passwordElem.classList.add(stateClasses.invalidField);
		}
		if (!email) {
			emailElem.classList.add(stateClasses.invalidField);
		}

		if (!email || !password) return;

		const data = {
			email,
			password,
			type: 'affiliate_user',
			set_token: 1
		};

		tuneInstance.authenticationFindUserByCredentials(data).then((response) => {
			if (response.data && response.data.token) {
				const userToken = response.data.token;
				const url = process.env.SITE_URL;
				window.location.href = `${url}/remote_login/${userToken}`;
			} else {
				passwordElem.classList.add(stateClasses.invalidField);
				emailElem.classList.add(stateClasses.invalidField);
				console.log('Authorization failed');
			}
		});
	});

	$resetPopupForm.addEventListener('submit', (event) => {
		event.preventDefault();

		const emailElem = document.querySelector('#userResetEmail');
		const email = emailElem.value;
		$resetPopupForm.querySelector('button').disabled = true;

		const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

		load(recaptchaKey).then((recaptcha) => {
			recaptcha
				.execute('contact')
				.then((token) => {
					// console.log('reCAPTCHA token:', token);
					const recaptchaResponse = document.querySelector('#recaptchaResponse');
					recaptchaResponse.value = token;

					tuneInstance.authenticationResetEmail(email).then((response) => {
						if (response.data && response.data.new_password) {
							// Send new password to email
							const firstname = response.data.first_name;
							const newPassword = response.data.new_password;
							const text = `${firstname} your new password ${newPassword} for email ${email}`;

							sendMail
								.sendResetEmail(email, 'Your new password', text, token)
								.then((responseEmailSend) => {
									if (responseEmailSend && responseEmailSend.status !== false) {
										if ($resetPopup.classList.contains(stateClasses.popupErrorState)) {
											$resetPopup.classList.remove(stateClasses.popupErrorState);
											if (typeof $popupErrorText !== 'undefined' && $popupErrorText != null) {
												$popupErrorText.innerHTML = '';
											}
										}
										openPopup($successResetPopup);
									} else {
										// Show error or modal
										console.error('Email sending failed:', responseEmailSend.message);

										displayErrors(responseEmailSend.error, $popupErrorText);

										$resetPopup.classList.add(stateClasses.popupErrorState);
									}
								})
								.catch((error) => {
									// Show error or modal
									console.error('Error:', error.message);
								})
								.finally(() => {
									$resetPopupForm.querySelector('button').disabled = false;
								});
						} else {
							$resetPopupForm.querySelector('button').disabled = false;
							console.log('Show error. Show message or modal', response);

							displayErrors(response.errors, $popupErrorText);

							$resetPopup.classList.add(stateClasses.popupErrorState);
						}
					});
				})
				.catch((error) => {
					console.error('Error during reCAPTCHA execution:', error);
				})
				.finally(() => {
					$resetPopupForm.querySelector('button').disabled = false;
				});
		});
	});
}

export default loginPopup;
