import './app.scss';

import { pageLoad } from './js/utils';
import forms from './js/components/forms';
import loginPopup from './js/components/loginPopup';
import registrationPopup from './js/components/registrationPopup';

function loadFunc() {
	setTimeout(() => {
		document.body.classList.add('--loaded-state');
	}, 500);

	loginPopup();
	forms();
	registrationPopup();
}

pageLoad(() => {
	loadFunc();
});
