function forms() {
	const fieldItems = document.querySelectorAll('.form-input');

	if (fieldItems.length) {
		fieldItems.forEach((item) => {
			const $togglePassword = item.querySelector('.js-showValueButton');
			const $password = item.querySelector('.js-passwordInput');
			if (typeof $togglePassword !== 'undefined' && $togglePassword != null) {
				$togglePassword.addEventListener('click', () => {
					if (typeof $password !== 'undefined' && $password != null) {
						const type = $password.getAttribute('type') === 'password' ? 'text' : 'password';
						$password.setAttribute('type', type);
					}
					$togglePassword.classList.toggle('show-value-button--visible-state');
				});
			}
		});
	}
}
export default forms;
